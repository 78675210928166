import React, { useEffect, useState } from "react";
import PaymentMessage from "../components/PaymentMessage/PaymentMessage";
import Page from "../components/Page";

const Payment = ({ location }) => {
  const [paymentOK, setPaymentOK] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let operation = params.get("operation");

    if (operation === "ok") {
      setPaymentOK(true);
    } else {
      setPaymentOK(false);
    }
  }, [location]);

  return (
    <Page title="Payment" description="Payment confirmation page">
      <PaymentMessage paymentOK={paymentOK} />
    </Page>
  );
};

export default Payment;
