import React from "react";
import PropTypes from "prop-types";
import { Container } from "@mui/material";
import SectionTitle from "../Common/SectionTitle";
import { useTranslation } from "react-i18next";

const PaymentMessage = ({ paymentOK }) => {
  const { t } = useTranslation();
  return (
    <section className="section" id="payment-message">
      <Container>
        <SectionTitle
          title={t(
            paymentOK
              ? "payment-message-section.title.ok"
              : "payment-message-section.title.nok"
          )}
          subtitle={t(
            paymentOK
              ? "payment-message-section.subTitle.ok"
              : "payment-message-section.subTitle.nok"
          )}
          desc={t(
            paymentOK
              ? "payment-message-section.description.ok"
              : "payment-message-section.description.nok"
          )}
        />
      </Container>
    </section>
  );
};

PaymentMessage.propTypes = {
  paymentOK: PropTypes.bool.isRequired,
};

export default PaymentMessage;
